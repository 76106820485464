<template>
  <div class="box">
    <header>
      <el-menu :default-active="$route.path" active-text-color="#fff" :router="true" background-color="#242424" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="/menu/userManage">用户管理</el-menu-item>
        <el-menu-item index="/menu/ebook">电子书</el-menu-item>
        <template v-if="jurisdiction">
          <el-menu-item index="/menu/public">研学发布</el-menu-item>
          <el-menu-item index="/menu/studyCard">学习卡片</el-menu-item>
          <el-menu-item index="/menu/ranking">关注度排名统计</el-menu-item>
          <el-menu-item index="/menu/counselor">咨询师管理</el-menu-item>
          <el-menu-item index="/menu/exercise">练习题管理</el-menu-item>
          <el-menu-item index="/menu/studySign">学业签管理</el-menu-item>
          <el-menu-item index="/menu/articleManage">书友文章管理</el-menu-item>
        </template>

      </el-menu>
      <div class="fr">
        <span>{{userInfo.username}}</span>
        <span style="margin-right: 10px;">{{userInfo.nickname}}</span>
        <span @click="$router.push('/index')">首页 / </span><span @click="quit">退出</span>
      </div>
    </header>
    <main class="box-content">
        <router-view></router-view>
    </main>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
  import Footer from '../../components/footer.vue';
export default {
  name: 'user',
  data() {
    return {
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      png: require('../../assets/image/20200215103340_ynmed.jpg'),
      activeIndex: '1',
      jurisdiction: false,
    }
  },
  components: {
    Footer,
  },
  provide() {
    return {
      loginOut:this.quit
    }
  },
  mounted() {
    let role = JSON.parse(sessionStorage.getItem('roleInfo')).roleName;
    if(role == 'root') {
      this.jurisdiction = true;
    }else {
      this.jurisdiction = false;
    }
  },
  methods: {
    handleSelect(key) {
      this.$router.push(key);
    },
    quit() {
      this.$router.push('/login');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('roleInfo');
      sessionStorage.removeItem('userInfo');
    },
  }
}
</script>

<style scoped lang="less">
  .box{
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  header{
    background-color: #242424;
    color: #fff;
    min-width: 1200px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    .userinfo{
      background-color: #de474e;
      display: inline-block;
      padding: 0 10px;
      text-align: center;
      img{
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 50%;
        vertical-align: middle;
      }
    }
    .fr{
      position: absolute;
      right: 20px;
      span{
        cursor: pointer;
      }
      span:first-child{
        margin-right: 10px;
      }
    }
    .el-menu{
      margin-left: 20px;
      border: none;
      /deep/ .el-menu-item{
        border-bottom-color: #fff;
      }
    }
    .el-menu--horizontal>.el-menu-item{
      height: 50px;
      line-height: 50px;
    }
  }
  .box-content{
    flex: 1;
    min-height: 1px;
    width: 100%;
    overflow: hidden;
  }
</style>
